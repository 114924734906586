<template>
  <div class="container">
    <PageTitle>付款紀錄</PageTitle>
    <hr />
    <h4>查詢學生</h4>
    <el-select
      class="studentSelect"
      placeholder="搜尋學生"
      style="width: 100%"
      :value="selectedUser"
      clearable
      filterable
      remote
      :remote-method="fetchUsers"
      @change="fetchPayments"
    >
      <el-option
        :key="user.id"
        :value="user.id"
        :label="`${user.email} (${user.name})`"
        v-for="user in users"
      />
    </el-select>
    <el-tabs v-model="activeTabName">
      <el-tab-pane label="使用記錄" name="transaction">
        <TransactionTable />
      </el-tab-pane>
      <el-tab-pane label="儲值紀錄" name="balance">
        <IncomeTable />
      </el-tab-pane>
      <el-tab-pane label="消費記錄" name="payment">
        <ExpenseTable />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import IncomeTable from "@/views/toefl/payments/IncomeTable.vue";
import ExpenseTable from "@/views/toefl/payments/ExpenseTable.vue";
import TransactionTable from "@/views/toefl/payments/TransactionTable.vue";

export default {
  metaInfo() {
    return {
      title: "Payments - " + this.CompanyName
    };
  },
  components: {
    PageTitle,
    IncomeTable,
    ExpenseTable,
    TransactionTable
  },
  computed: {
    users() {
      return this.$store.getters["payment/users"];
    },
    selectedUser() {
      return this.$store.getters["payment/selectedUser"];
    }
  },
  data() {
    return {
      activeTabName: "transaction"
    };
  },
  async created() {
    if (this.$route.query.email) {
      const selectedUser = await this.$store.dispatch("payment/fetchUsers", {
        keyword: this.$route.query.email
      });
      this.fetchPayments(selectedUser[0].id);
    }
  },
  methods: {
    fetchUsers(keyword) {
      this.$store.dispatch("payment/fetchUsers", { keyword });
    },
    fetchPayments(userId) {
      const payload = {
        page: 1,
        user_id: userId
      };
      this.$store.commit("payment/setSelectedUser", userId);
      this.$store.dispatch("payment/fetchExpenses", payload);
      this.$store.dispatch("payment/fetchIncomes", payload);
      this.$store.dispatch("payment/fetchTransactions", payload);
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  padding-top: 40px;
}

.studentSelect ::v-deep .el-input__inner {
  cursor: text;
}
</style>
